body{
  --whitColor : #F5F7FA;
  --BlackColor : #111B33;
  --Figtree: "Figtree", sans-serif;
  --Satoshi: 'Satoshi', sans-serif;
 
  background-color: #F5F7FA !important;
}


.navbar-light .navbar-toggler-icon{
  background-image:url('./assets/align-right.svg') !important;
  transition: 7s;
}
.navbar-expand-lg .offcanvas .offcanvas-body{
  justify-content: end;
}

.nav-link{
  color: #111B33 !important;
  font-size: 21px !important;
  font-weight: 400 !important;
  transition: 0.7s !important;
}
.nav-link:hover{
  color: #2692CA !important;
  transition: 0.7 !important;
}
.nav-link:focus{
  color: #2692CA !important;
}

.navbar{
  box-shadow: none !important;
}
.Startdev{
  padding-bottom: -10px !important;
}
.Startdev > p{
  font-family: 'Satoshi', sans-serif;
  font-size: 19px;
  text-align: center;
  border-radius: 50px;
  text-align: center;
  background: rgb(38,218,188);
  background: linear-gradient(90deg, rgba(38,218,188,1) 0%, rgba(38,67,219,1) 100%);
  width: 423px;
  color: #F5F7FA;
  height: 40px;
 text-align: center;
 font-weight: 500;
}

.Start{
  margin-top: 187px;
  text-align: center !important;
  
}
.bigText{
  margin: 0 !important;
  padding: 0  !important;
 
}
.bigText > p {
  font-family: "Figtree", sans-serif !important;
  font-size: 80px;
  color: #111B33;
}
.smallText > p{
  font-size: 12px;
  width: 60% !important;
  font-weight: normal;
}
.buttons{
  margin-top: 44px;
}
.buttons .btn{
width: 260px;
height: 72px;
border-radius: 10px;
font-family:  'Satoshi', sans-serif;
font-size: 19px !important;
font-weight: bold;
box-shadow: none !important;
}
.b1{
  color: #F5F7FA !important;
  background-color: #111B33 !important;
}
.b2{
  background-color: var(--whitColor) !important;
  color: var(--BlackColor) !important;
  border: 1px solid var(--BlackColor) !important;
}

/* Start featuers */
.featuers{
  margin-top: 184px;
  color: #364872;
}
.featuers h5{
  font-size: 21px !important;
  font-weight: bold;
  font-family: "Figtree", sans-serif !important;
  text-align: center;
}
.featuers p{
  font-size: 15px;
  font-family: 'Satoshi', sans-serif;

}
.featuers h3{
  margin-top: 35px;
  font-size: 17px;
  font-weight: bold;
  font-family: "Figtree", sans-serif !important;
}
.featuers h1{
  margin-top: 40%;
}
.featuers .card{
  border-radius: 20px;
  padding-left: 10px;
  padding-top: 10px;
  margin-left: -15px;
  min-height: 486px;
}
.liner img {
  width: 100%;
    position: absolute;
    top: 90px;
    left: 0;
    height: 80%;
}
.imgcard2 img {
  margin-top: 0px !important;
  width: 100%;
    position: absolute;
    top: 180px;
    left: 0;
    height: 63%;
}

.featuers img{
  border-radius: 50px;
}
.featuers img, svg{
  border-radius: 50px;
}
.progress-bar {
  border-radius: 20px !important;
}

.progress-bar::before {
  content: "";
  display: block;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(90deg, rgba(38,67,219,1) 0%,rgba(38,218,188,1) 100%);
}
.featuers span{
  font-size: 10px;
  font-family: var(--Figtree);
  font-weight: 500;
}
.featuers .we{
  font-size: 96px;
  font-family: 'Onyx', sans-serif;
}

.featuers h1{
  font-size: 40px;
  font-family: 'Onyx', sans-serif;
  background: linear-gradient(90deg,rgba(38,218,188,1) ,  rgba(38,67,219,1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For Safari/WebKit */
  background-clip: text;
  background-clip:image
}
/*End Start featuers */

/* Start TrustedBy */
.TrustedBy{
margin-top: 162px;
}
.TrustedBy h6{
  font-size: 32px;
  font-weight: 800;
  font-family: var(--Figtree);
  background: linear-gradient(90deg, rgba(38,218,188,1) 30%, rgba(38,67,219,1) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For Safari/WebKit */
  background-clip: text;
}
.TrustedBy p{
  font-size: 26px;
  font-family: var(--Satoshi);
  font-weight: 500;
  
}
.TrustedBy i{
  color: #263EDC;
  font-size: 15px;
}
.TrustedBy span{
  margin-top: 5px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  
}

.logos:after {
  right: 0;
 
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

/*End  TrustedBy */

/*  Services */
.Services {
  margin-top: 162px !important;
  margin-bottom: 0px !important;
}
.slick-slider .slick-track, .slick-slider .slick-list{
  margin-bottom: 0px !important;
  height: 100% !important;
}
.Services h6{
  font-size: 32px;
  font-weight: 800;
  font-family: var(--Figtree);
  background: linear-gradient(90deg, rgba(38,218,188,1) 30%, rgba(38,67,219,1) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For Safari/WebKit */
  background-clip: text;
}
.slick-slide{
  margin-left: 5px;
}
.Services p{
  font-size: 26px;
  font-family: var(--Satoshi);
  font-weight: 500;
  color: var(--whitColor);
  color: var(--BlackColor);
}
.Services .card{
background-color: black !important;
border-radius: 20px !important;
color: var(--whitColor);
min-height: 333px !important;
}
.Services h3{
  font-size: 42px;
  font-weight: 400;
  color: var(--whitColor) !important;
}
.Services h5{
  font-size: .33rem;
}
.Services span{
  font-size: 9px;
}
.Services .card-footer{
  border-top: none !important;
  margin-top: 30px;
  
}

.slick-next , .slick-prev{
  content: '→'!important; 
    background-color: rgb(0, 0, 0, 0.8)!important; 
    background-size:20px !important;
    font-size: 70px!important; 
    border-radius: 50px !important; 
    z-index: 10;
    top: 25% !important;
}
.slick-next{
  right: 25px !important;
}
.slick-prev{
  left: 25px !important;
}
.slick-next:before{
  color: black !important;
}
.slick-prev:before{
  color: black !important;
}
.Services button {
  background-color:  rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(100px);
    width: 60px !important;
    height: 60px;
    border-radius: 50px !important; 
    border: 1px solid black !important;
    -webkit-backdrop-filter: blur(10px);
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    transition: 0.9s all;
}
.Services button:active{
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0));
}
.Services button:hover{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.99), rgba(255, 255, 255, 0.2));
}
.slider-container{
 
  padding-top: 0;
}
.slick-track{
  margin-bottom: 0px !important;
}
/*End  Services */

/*  OurProduct */
.OurProduct{
margin-top: 250px;
}
.OurProduct h6{
  font-size: 32px;
  font-weight: 800;
  font-family: var(--Figtree);
  background: linear-gradient(90deg, rgba(38,218,188,1) 30%, rgba(38,67,219,1) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For Safari/WebKit */
  background-clip: text;
}
.OurProduct p{
  font-size: 26px;
  font-family: var(--Satoshi);
  font-weight: 500;
}
.OurProduct h5{
 font-size: 19px;
 font-weight: bold;
 font-family: var(--Figtree);
 color: #364872;
 text-align: left;
}
.OurProduct .card-text{
 font-size: 15px;
 font-weight: normal;
 font-family: var(--Satoshi);
 color: #364872;
 text-align: left;
}
.wrap2{
  background-image: url('./assets/New folder/Path\ 642.png');
  background-size:cover;
  background-position:center ;
  background-repeat:repeat-y !important;
  margin-top: 60px !important;
}
.centerIconn{
  position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-right: 55px;
}
.OurProduct .card{
  padding-left:57px !important;
  border-radius: 20px;
  margin-top: 24px;
  transition: .7s;
  min-height: 244px;
}
.OurProduct .proMarginTop{
  margin-top: 24px px !important;
}



/*End  OurProduct */




/*  Blogs */
.Blogs {
  margin-top: 162px !important;
}

.Blogs h4{
  font-size: 32px;
  font-weight: 800;
  font-family: var(--Figtree);
  background: linear-gradient(90deg, rgba(38,218,188,1) 30%, rgba(38,67,219,1) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For Safari/WebKit */
  background-clip: text;
}
.Blogs h5{
  font-size: 26px;
  font-family: var(--Satoshi);
  font-weight: 500;
  color: var(--whitColor);
  color: var(--BlackColor);
}
.Blogs h6{
  font-size: 32px;
  font-weight: bold;
  font-family: var(--Figtree);
  color: #364872;
}
.Blogs p{
font-family: var(--Satoshi);
font-size: 21px;
color: #828DA8;
font-weight: 400;
}
.Blogs .text{
  margin-top: 100px;
}
.Blogs .body{
  margin-top: 27px;
}
.text img{
  width:100%;
  height: 70%
}
/*End  Blogs */

/* Footer */
.footer{
  margin-top: 162px;
  background-color: black;
  color: #F0F3F7;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}
.footer .row1{
  margin-top: 94px;
}
.footer .row2{
  margin-top: 30px;
}
.form .card{
  background-color: #141414;
 
}
.story{
  
}
.form input{
color: #7C7C7C !important;
font-size: 14px;
font-family: var(--Satoshi);
background-color: #2D2D2D !important;
border: none !important;
border-radius: 7px;
height: 46px;
padding-left: 15px;
width: 100%;
}
.form input:focus{
  border: none;
  outline: unset;
}
.form label{
  color: #7C7C7C !important;
font-size: 14px;
font-family: var(--Satoshi);
}
.form button{
  width: 100%;
  font-size: 17px;
  font-family: var(--Satoshi);
  font-weight: 700;
}
.footer .row3{
  margin-top: 100px;
}
.footer .row4{
  margin-top: 96px;
}
.form{
  margin-top: 169px !important;
}
.footer h2{
font-size: 26;
font-weight: bold;
font-family: var(--Figtree);
}
.footer p{
font-size: 16;
font-weight:400;
font-family: var(--Satoshi);
}
.footer h1{
font-size: 71px;
font-weight:300;
font-family: var(--Figtree);
}
.footer img{
  width: 100%;
    position: relative;
    height: 100%;
    
}

@keyframes h1footer {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.animdiv {
  overflow: hidden;
  padding: 60px 0;
  white-space: nowrap;
  position: relative;
}

.animdiv:before,
.animdiv:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}
.animdiv:before {
  left: 0;
  
}

.animdiv:hover .anim {
  animation-play-state: paused;
}

.animdiv:after {
  right: 0;
 
}
.anim {
  display: inline-block;
  animation: 35s h1footer infinite linear;
}

/*End  Footer */

/* Get Start page */
.getstart{
  background-color: #000000;
  color: #F0F3F7;
  height: 100%;
}
.getstart h4{
  font-size: 43px;
  font-family: var(--Figtree);
  font-weight: 400;
}
.getstart h1{
  color: #F0F3F7;
  font-size: 110px;
  font-family: var(--Figtree);
  font-weight:300
}
.getstart input{
  color: #7C7C7C !important;
  font-size: 14px;
  font-family: var(--Satoshi);
  background-color: #2D2D2D !important;
  border: none !important;
  border-radius: 7px;
  height: 56px;
  padding-left: 15px;
  width: 100%;
  }
  .getstart input:focus{
    border: none;
    outline: unset;
  }
  .getstart label{
    color: #7C7C7C !important;
  font-size: 14px;
  font-family: var(--Satoshi);
  }
  .getstart button{
    width: 100%;
    height: 56px;
    font-size: 17px;
    font-weight: 700;
  }
  .getstart .row4{
    margin-top: 70px;
  }
  .getstart a{
   color: #F0F3F7 !important;
   margin-bottom: 20px;
   font-size: 17px;
   font-family: var(--Satoshi);
   font-weight: 500;
   transition: .7s;
  }
  .getstart a:hover{
    color: #2692CA !important;
  }
  .getstart a:focus{
    color: #2692CA !important;
  }

  .modal p{
    font-size: 16px;
    font-family: var(--Satoshi);
    font-weight: 400;
    color: #7C7C7C !important;
  }
/*End Get Start page */

/*----------------------------------------------------------------*/
                  /*  Responsive Meduim Screen */
/*----------------------------------------------------------------*/

@media (min-width: 768px) and (max-width: 991.98px) {
  .bigText > p {
    font-family: "Figtree", sans-serif !important;
    font-size: 46px;
    color: #111B33;
  }
  .smallText > p{
    font-size: 12px;
    width: 100% !important;
    font-weight: normal;
  }

.featuers img{

}
.featuers h3{
  font-size: 15px;
}
.text img{
  width:100%;
  height: 50%
}
.footer img{
  height: 100%;
}
.wrap2{
  background-image: url('./assets/New folder/Path 643.png');
}
.slider-container{
  height:450px !important
}
.slider{
  overflow: hidden;
  width:300px !important;
}
.story{
  text-align: center !important;
  margin-top: 75px;
}
.form button{
  width: 332px;
}
.getstart h1{
  font-size: 440% ;
}
}
/*----------------------------------------------------------------*/
                  /* End Responsive Meduim Screen */
/*----------------------------------------------------------------*/





/*----------------------------------------------------------------*/
                  /* Responsive small Screen */
/*----------------------------------------------------------------*/
@media (max-width: 767px) {
  .Startdev{
    height: 24px;
  }
  .Startdev > p{ 
    font-size: 11px;
    font-family: var(--Satoshi);
    width: 85%;
    padding-top: 3px;
    height: 24px;

  }

  .bigText > p {
    font-family: "Figtree", sans-serif !important;
    font-size: 23px;
   
  }
  .smallText > p{
    font-size: 12px;
    width: 100% !important;
    font-weight: normal;
  }


  /* Buttons */
  .buttons{
    margin-top: 30px;
  }
  .buttons .btn{
    width: 100%;
    height: 46px;
    border-radius: 10px;
    font-family:  'Satoshi', sans-serif;
    font-size: 12px !important;
    font-weight: bold;
    
    }
.b1 {
  margin-left: 0px;
}
.colb1{
  padding-right: 0px !important;
}
  /* Buttons */

  /* Featuers */
  .featuers h5{
    font-size: 17px;
  }
  .featuers p{
    font-size: 12px;
  }
  .featuers h3{
    font-size: 14px;
  }
  /*End Featuers */
.featuers{
  margin-top: 71px;
  color: #364872;
}

/* Start TrustedBy */
.TrustedBy h6{
  font-size: 17px;
  font-weight: 800;
}
.TrustedBy p{
  font-size: 17px;
}
.TrustedBy{
  margin-top: 117px;
  }

  

/*End Start TrustedBy */


/*  Services */
.Services {
  margin-top: 100px !important;
}
.Services h6{
  font-size: 17px;
}
.Services p{
  font-size: 14px;
}
.slider-container{
  
}
/*End  Services */

/*  OurProduct */
.OurProduct{
  margin-top: 112px !important;
  }
  .OurProduct h6{ 
font-size: 17px;
font-weight: 800;
  }
  .OurProduct p{
    font-size: 15px;
    font-weight: 500;
    margin-top: 7px;
  
  }
  .wrap2{
    background-image: url('./assets/New folder/Path 643.png') !important;
    background-size: cover !important;
    margin-top: 29px !important;
  }
  .OurProduct h5{
    font-size: 18px;
    
  }
  .OurProduct .card-text{
    font-size: 15px;
  }
  .OurProduct .card{
    min-height: 106px;
  }
/*  OurProduct */

/*  Blogs */
.Blogs {
  margin-top: 119px !important;
}
.Blogs h4{
  font-size: 17px;
  font-weight: 800;
}
.Blogs h5{
  font-size: 15px;
}
.Blogs h6{
  font-size: 21px;
}
.Blogs p{
  font-size: 12px;
}
.text{
  margin-top: 33px !important;
}

/*  Blogs */
/* Footer */
.footer{
  margin-top: 90px;
}
.footer h1{
  font-size: 60px;
}
.footer .row3{
  margin-top: 40px;
}
.footer img{
  height: 100%;
}
.story{
  text-align: center !important;
  margin-top: 20px;
}
.form button{
  margin-top: 43px !important;
}
.getstart .powered{
  margin-top: 86px;
}
/* Footer */
}





/*----------------------------------------------------------------*/
                  /* End Responsive small Screen */
/*----------------------------------------------------------------*/


/*----------------------------------------------------------------*/
                  /* Responsive imags */
/*----------------------------------------------------------------*/

@media (min-width: 992px) and (max-width: 1199px) {
  .liner img {
    width: 100%;
      position: absolute;
      top: 120px !important;
      left: 0;
      height: 74% !important;
  }
  .getstart h1{
    margin-top: 20px;
    color: #F0F3F7;
    font-size: 91px;
    font-family: var(--Figtree);
    font-weight:300
  }
}